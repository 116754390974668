import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=e15d239e&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=e15d239e&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e15d239e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/app/components/Banner.vue').default,Logo: require('/app/components/Logo.vue').default,Menu: require('/app/components/Menu.vue').default,Button: require('/app/components/Button.vue').default,Burger: require('/app/components/Burger.vue').default,Paperform: require('/app/components/Paperform.vue').default,Header: require('/app/components/Header.vue').default})


export default {
  props: {
    link: {
      type: String,
      default: "https://calendly.com/aleo-alpha/rendez-vous-decouverte-aleo",
    },
  },
  data() {
    return {
      isCalendlyLoaded: typeof Calendly !== "undefined",
    };
  },
  head() {
    return {
      link: [
        {
          hid: "calendly-css",
          rel: "stylesheet",
          type: "text/css",
          href: "https://assets.calendly.com/assets/external/widget.css",
        },
      ],
      script: [
        {
          hid: "calendly-js",
          skip: this.isCalendlyLoaded,
          src: "https://assets.calendly.com/assets/external/widget.js",
          defer: true,
          callback: () => {
            this.activeCalendly();
          },
        },
      ],
    };
  },
  mounted() {
    if (this.isCalendlyLoaded) {
      this.activeCalendly();
    }
  },
  methods: {
    activeCalendly() {
      Calendly.initPopupWidget({ // eslint-disable-line
        url: this.link,
      });
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/quote.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".section-testimonial__wrapper[data-v-1719bfeb]{box-shadow:0 20px 28px rgba(57,7,63,.1)}.content img[data-v-1719bfeb]{width:100%;border-radius:16px;margin-bottom:40px}.content p[data-v-1719bfeb]{font-weight:500;font-size:18px;line-height:28px;color:#413049;margin-bottom:26px}.content p span[data-v-1719bfeb]{font-weight:800;font-size:12px;line-height:16px;letter-spacing:.08em;text-transform:uppercase;font-style:normal;margin-top:16px;display:block}.content h2[data-v-1719bfeb]{font-style:italic;font-weight:900;font-size:32px;line-height:36px;color:#39073F;margin-bottom:28px;}@media (min-width: 1280px){.content h2[data-v-1719bfeb]{font-size:40px;line-height:44px}}.content .quote[data-v-1719bfeb]{--tw-text-opacity:1;color:rgb(231 51 49 / var(--tw-text-opacity));font-style:italic;font-weight:500;font-size:24px;line-height:33px;position:relative;margin-bottom:50px;margin-top:56px}.content .quote[data-v-1719bfeb]:before{content:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:absolute;opacity:.2;transform:translate(-30px,-30px);}@media (min-width: 1280px){.content .quote[data-v-1719bfeb]:before{transform:translateX(-140%);opacity:1}}@media (min-width: 768px){.content .quote[data-v-1719bfeb]{font-size:32px;line-height:44px}}@media (min-width: 1280px){.content .quote[data-v-1719bfeb]{transform:translateX(115px)}}.planete[data-v-1719bfeb]{filter:drop-shadow(8px 28px 60px rgba(55,6,2,.43))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

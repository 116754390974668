
export default {
  props: {
    isItemsWhite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSolutionActive: false,
      isTestimonialActive: false,
      isAboutActive: false,
      menuItems: [
        {
          label: "Site web",
          subtitle: "Une vitrine digitalement incontournable.",
          image: "icon-site-web-menu.png",
          alt: "Ordinateur",
          link: "web",
        },
        {
          label: "Référencement naturel",
          subtitle: "Direction le top des résultats Google.",
          image: "icon-seo-menu.png",
          alt: "SEO",
          link: "ref-naturel",
        },
        {
          label: "Réseaux sociaux",
          subtitle: "Votre entreprise sous son meilleur jour.",
          image: "icon-reseaux-sociaux-menu.png",
          alt: "Offre Réseaux sociaux",
          link: "facebook",
        },
      ],

      menuItems2: [
        {
          label: "Publicité Réseaux Sociaux",
          subtitle: "Un coup de boost pour votre notoriété.",
          image: "icon-gestion-sociaux-menu.png",
          alt: "Publicité Réseaux sociaux",
          link: "gestion-rs",
        },
        {
          label: "Publicité sur Google",
          subtitle: "À des années-lumière de la concurrence.",
          image: "icon-google-ads-menu.png",
          alt: "Communiquez !",
          link: "seo",
        },
        {
          label: "Logo et supports imprimés",
          subtitle: "Créez un univers à votre image.",
          image: "icon-graphisme-menu.png",
          alt: "Pack graphisme",
          link: "graphism",
        },
      ],

      testimonials: [
        {
          image: "case-studies/mockup-le-repere.jpg",
          link: "le-repere",
          title: "Le Repère",
          subtitle: "Se démarquer grâce à une identité visuelle à son image.",
        },
        {
          image: "case-studies/mockup-site-web-museau-seduisant.jpg",
          link: "museau-seduisant",
          title: "Au Museau Séduisant",
          subtitle: "Être visible sur Google avec un site web et un logo pros.",
        },
        {
          image: "case-studies/mockup-reiki-2.png",
          link: "reiki",
          title: "Reiki & Bergamote",
          subtitle: "Booster son activité avec un site attrayant.",
        },
      ],
    };
  },

  computed: {
    isSmallerThanMd() {
      return this.$breakpoints.sMd;
    },
  },

  methods: {
    updateMenuState() {
      this.$emit("menustate", false);
      document.body.style.height = "unset";
      document.body.style.overflow = "unset";
    },
  },
};
